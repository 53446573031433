import {
  AnimationTriggerMetadata,
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function FadeInOut(
  timingIn: number,
  timingOut: number
): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(timingIn + 'ms 40ms ease-in-out', style({ opacity: 1 })),
    ]),
    transition(':leave', [animate(timingOut, style({ opacity: 0 }))]),
  ]);
}
